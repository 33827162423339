<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 十月武汉VB报名记录 </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入姓名" v-model="name" allowClear/>
        <a
          :href="$config.target + '/hxclass-management/kfxh_sing_up_two/exportKfxhSingUpTwo'"
          target="_blank"
          rel="noopener noreferrer"
          class="left"
        >
          <a-button style="margin-right: 10px">导出表格</a-button>
        </a>
        <a-button type="primary" class="btn" @click="pageNumber=1,FormData()">搜索</a-button>
        <a-button class="all_boder_btn" @click="name=''">重置</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a-popconfirm
              v-if="item.isConfirm == 0"
              title="是否确认状态?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onOk(item)"
            >
              <a>确认</a>
            </a-popconfirm>
            <span v-else>-</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "100",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "性别",
    dataIndex: "sex",
  },
  {
    title: "单位",
    dataIndex: "workUnit",
  },
  {
    title: "证件号",
    dataIndex: "cardNumber",
  },

  {
    title: "手机号",
    dataIndex: "contactPhone",
  },
  {
    title: "是否开具发票",
    align: "center",
    dataIndex: "isInvoice",
  },
  {
    title: "支付方式",
    align: "center",
    dataIndex: "paymentType",
  },
  {
    title: "是否确认",
    align: "center",
    dataIndex: "isConfirmName",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns,
      total: 0,
      name: '',
      loading:false,
      pageNumber:1,
      pageSize: 10,
      tableData: [],
    };
  },
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.FormData();
    },
    // 确认
    onOk(e){
      this.$ajax({
        url: '/hxclass-management/kfxh_sing_up_two/confirmTwo',
        method: 'post',
        params: {
          id: e.id
        }
      }).then(res => {
        if(res.code == 200){
          this.FormData()
        }
      })
    },
    FormData(){
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/kfxh_sing_up_two/selManagerTwoList",
        params: {
          name: this.name,
          pageNum: this.pageNumber,
          pageSize: this.pageSize
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
        }
      });
    }
  },
  created () {
    this.FormData()
  },
  mounted () { },
  destroyed () { },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.modal_box {
  display: flex;
  align-items: center;
  .tag {
    flex-shrink: 0;
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  // /deep/.ant-table {
  //   border: 1px solid #eeeeee;
  //   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  // }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.left {
  margin-left: 16px;
}
</style>
